// video element
const supportsVideo = !!document.createElement("video").canPlayType;
if (supportsVideo) {
// Select all video elements on the page
	const videos = document.querySelectorAll("video");

// Loop through each video element
	videos.forEach((video) => {
		// Hide the default controls
		video.controls = false;

		// Display the user defined video controls
		const playpause = video.nextElementSibling.querySelectorAll('.video__controls__play');

		// Add a click event listener to the play/pause button
		playpause.forEach(playpause => {
			playpause.addEventListener("click", (e) => {
				if (video.paused || video.ended) {
					video.play();
					playpause.classList.add('act');
					video.parentElement.classList.add('act');

				} else {
					video.pause();
					playpause.classList.remove('act');
					video.parentElement.classList.remove('act');
				}
			});
		});

	});
}

// accordion

const faqItems = document.querySelectorAll('.faq__item');

faqItems.forEach(item => {
	const question = item.querySelector('.faq__question');
	question.addEventListener('click', () => {
		const isOpen = item.classList.contains('open');

		// get the height of the clicked answer
		const answer = item.querySelector('.faq__answer');
		const answerHeight = answer.scrollHeight;

		if (!isOpen) {
			item.classList.add('open');
			answer.style.height = answerHeight + 'px';
		} else {
			item.classList.remove('open');
			answer.style.height = '0';
		}
	});
});

// // chuckup more content
// const moreItems = document.querySelectorAll('.chuckup__more__item');
//
// moreItems.forEach(item => {
// 	const question = item.querySelector('.chuckup__more__title');
// 	question.addEventListener('click', () => {
// 		const isOpen = item.classList.contains('open');
//
// 		// close all open items and set the css height to zero
// 		moreItems.forEach(item => {
// 			item.classList.remove('open');
// 			const answer = item.querySelector('.chuckup__more__content');
// 			answer.style.height = '0';
// 		});
//
// 		// get the height of the clicked answer
// 		const answer = item.querySelector('.chuckup__more__content');
// 		const answerHeight = answer.scrollHeight;
//
// 		if (!isOpen) {
// 			item.classList.add('open');
// 			answer.style.height = answerHeight + 'px';
// 		}
// 	});
// });

// 2-click consent function for the youtube mask element
// get the label text of the consent button
var maskYoutube = document.querySelectorAll('.mask_youtube');
if(maskYoutube) {
	maskYoutube.forEach(function(element) {
		var youtubeLabelEnable = element.querySelector('.mask_youtube__consent-button').dataset.youtubeTextEnable;
		var youtubeLabelDisable = element.querySelector('.mask_youtube__consent-button').dataset.youtubeTextDisable;

		// function that activates videos after youtube consent was set
		var activateVideo = function() {
			// load every video iframe on the page if the consent button gets clicked
			document.querySelectorAll('.mask_youtube__video').forEach(function(video) {
				video.classList.add('act');
				// get the video src from the data attribute
				var iframeSrc = video.querySelector('.mask_youtube__iframe-src').dataset.src;
				// get the iframe video object
				var emptyIframe = video.querySelector('.mask_youtube__iframe');

				// add src to the empty iframe
				emptyIframe.setAttribute('src', iframeSrc);
			});

			// switch the mask_youtube__consent button class and text
			document.querySelectorAll('.mask_youtube__consent-button').forEach(function(videoButton) {
				youtubeLabelEnable;
				youtubeLabelDisable;

				videoButton.classList.add('act');
				videoButton.innerHTML = youtubeLabelDisable;
			});

			sessionStorage.setItem('youtube_consent', '1');
		}

		var deactivateVideo = function() {
			document.querySelectorAll('.mask_youtube__video').forEach(function(video) {
				video.classList.remove('act');
				video.querySelector('.mask_youtube__iframe').removeAttribute("src");
			});

			document.querySelectorAll('.mask_youtube__consent-button').forEach(function(videoButton) {
				youtubeLabelEnable;
				youtubeLabelDisable;

				videoButton.classList.remove("act");
				videoButton.innerHTML = youtubeLabelEnable;
			});

			sessionStorage.removeItem('youtube_consent');
		}

		if(sessionStorage.getItem('youtube_consent') == 1) {
			activateVideo();
		}

		element.querySelector('.mask_youtube__consent-button').addEventListener( "click", function() {
			if(this.classList.contains('act')) {
				deactivateVideo();
			} else {
				activateVideo();
			}
		});
	});
}

// video opt-in
// check if there are video elements with the class video__optin on the page
const videoOptin = document.querySelectorAll('.video__optin');
if(videoOptin) {
	// add an event listener to the click event on each video__optin element
	videoOptin.forEach(item => {
		// append a layer over the video elements to prevent clicking on the video
		const videoLayer = document.createElement('div');
		videoLayer.classList.add('video__optin__layer');

		// add a div container with the class video__optin__text and the data privacy text inside the video__optin__layer element
		const videoText = document.createElement('div');
		videoText.classList.add('video__optin__text');
		videoText.innerHTML = 'Mit dem Aufruf des Videos erklärst du dich einverstanden, dass Deine Daten an YouTube übermittelt werden und dass du die <a href="https://www.roehm.biz/navigationsseiten/datenschutz/" class="link-external" target="_blank">Datenschutzerklärung</a> gelesen hast<br> <a class="glightbox4 link-button link-button--big video__optin__play">Video starten</a>';

		// add videoText for the english version
		if(document.documentElement.lang == "en") {
			videoText.innerHTML = 'By accessing the video, you agree that your data will be transmitted to YouTube and that you have read the <a href="https://www.roehm.biz/en/navigationsseiten/data-protection/" class="link-external" target="_blank">data protection</a><br> <a class="glightbox4 link-button link-button--big video__optin__play">Start video</a>';
		}
		videoLayer.appendChild(videoText);


		// append the layer to the glightbox4 element
		const lightboxVideo = item.querySelector('.video__optin__link');
		lightboxVideo.appendChild(videoLayer);

		// prevent clicking on the a tag inside the video element
		const videoLink = item.querySelector('a.video__optin__link');
		// get the data-videohref attribute from the videoLink element
		const videoHref = videoLink.dataset.videohref;

		// add an event listener to the click event on the video__optin__play element
		const videoPlay = item.querySelector('.video__optin__play');
		// add the videoHref to the href of the videoPlay element
		videoPlay.setAttribute('href', videoHref);
	});
}


// to top button
if(document.querySelector(".top__arrow")) {
	// show the top__arrow element if the user scrolls down (use the intersection observer)
	const topArrow = document.querySelector('.top__arrow');
	const topArrowObserver = new IntersectionObserver(function(entries) {
		entries.forEach(entry => {
			if (entry.boundingClientRect.top < 0) {
				topArrow.classList.add('act');
			} else {
				topArrow.classList.remove('act');
			}
		});

	});
	topArrowObserver.observe(document.querySelector('.js--scroll--activator'));

	// scroll to top on click on the top__arrow element
	topArrow.addEventListener('click', () => {
		window.scrollTo({top: 0, behavior: 'smooth'});
	});
}


// glightbox
var lightbox = GLightbox();

var lightboxDescription = GLightbox({
	selector: '.glightbox2'
});
var lightboxVideo = GLightbox({
	selector: '.glightbox3',
	autoplayVideos: true,
	touchNavigation: false,
	preload: true
});


lightboxVideo.on('slide_changed', ({ prev, current }) => {
	console.log('Prev slide', prev);
	console.log('Current slide', current);

	const { slideIndex, slideNode, slideConfig, player } = current;

	if (player) {
		if (!player.ready) {
			// If player is not ready
			player.on('ready', (event) => {
				// Do something when video is ready
			});
		}

		player.on('play', (event) => {
			console.log('Started play');
		});

		player.on('volumechange', (event) => {
			console.log('Volume change');
		});

		player.on('ended', (event) => {
			console.log('Video ended');
		});
	}
});


var lightboxInlineIframe = GLightbox({
	selector: '.glightbox4',
	autoplayVideos: true,
	touchNavigation: false
});
